import * as React from "react";

import { PropertyShortlist } from "@shared-ui/lodging-property-details";
import { ShortlistToggleProvider } from "@shared-ui/trip-planning-travelpulse";

import { useLocalization } from "@shared-ui/localization-context";
import { Level, liveAnnounce } from "@egds/react-core/utils";

import { ShortListWrapperProps } from "./typings";
import { withStores } from "stores";

import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";

const WrappedShortList = PropertyBexApiWrapper(PropertyShortlist);

export const ShortListWrapper = withStores("uiPage")((props: ShortListWrapperProps) => {
  const { uiPage } = props;
  const { formatText } = useLocalization();

  // Can't test because useShortlistToggle is not exported
  /* istanbul ignore next */
  const handler = ({ message }: { message: string }) => {
    const notification = formatText("hotels.shortlist.saveToList.notification", message);

    uiPage.showNotification(notification, "favorite");
    liveAnnounce(notification, Level.POLITE);
  };

  return (
    <ShortlistToggleProvider dispatchHandler={handler}>
      <WrappedShortList context={props.context} />
    </ShortlistToggleProvider>
  );
});

export default ShortListWrapper;
