import * as React from "react";

import { PropertyShareActions } from "@shared-ui/lodging-property-details";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ShareActionsWrapperProps } from "./typings";

const WrapperShareActions = PropertyBexApiWrapper(PropertyShareActions);

export const ShareActionsWrapper = (props: ShareActionsWrapperProps) => {
  const { context, skipSsr } = props;
  const domain = context.site.domain;

  if (!domain) {
    return null;
  }

  return <WrapperShareActions context={context} skipSsr={skipSsr} domain={domain} />;
};
export default ShareActionsWrapper;
