import * as React from "react";
import { PropertySaveTripItem } from "@shared-ui/lodging-property-details";
import { SaveTripItemWrapperProps } from "./typings";
import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { buildStateProvider } from "@shared-ui/provided-state-context";
import { ValidatedInputsState } from "@shared-ui/trips-save-item";

const WrapperSaveTripItem = PropertyBexApiWrapper(PropertySaveTripItem);
const ValidatedInputsStateProvider = buildStateProvider(new ValidatedInputsState());

export const SaveTripItemWrapper = (props: SaveTripItemWrapperProps) => (
  <ValidatedInputsStateProvider>
    <WrapperSaveTripItem context={props.context} />
  </ValidatedInputsStateProvider>
);
export default SaveTripItemWrapper;
