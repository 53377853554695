import * as React from "react";

import { PropertySearchLink as SharedUiPropertySearchLink } from "@shared-ui/lodging-property-offers";

import { ShortListWrapper } from "./ShortListWrapper";
import { SaveTripItemWrapper } from "./SaveTripItemWrapper";
import { ShareActionsWrapper } from "./ShareActionsWrapper";

import { PropertyBexApiWrapper } from "components/shared/BexApiWrapper/PropertyBexApiWrapper";
import { ComponentProps } from "typings/flexFramework/FlexComponent";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";

const WrappedSearchLink = PropertyBexApiWrapper(SharedUiPropertySearchLink);

export const PropertySearchLink: React.FC<ComponentProps> = ({ context }) => {
  return (
    <UitkSpacing padding={{ block: "two" }}>
      <UitkLayoutFlex alignItems="center">
        <UitkLayoutFlexItem grow={1}>
          <div>
            <WrappedSearchLink context={context} useExternalLink={true} />
          </div>
        </UitkLayoutFlexItem>
        <UitkLayoutFlexItem>
          <ShareActionsWrapper context={context} />
        </UitkLayoutFlexItem>
        <UitkLayoutFlexItem>
          <div>
            <ShortListWrapper context={context} />
            <SaveTripItemWrapper context={context} />
          </div>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </UitkSpacing>
  );
};

export default PropertySearchLink;
